import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid"
import Fade from "react-reveal/Fade"
import JoinTheCrew from "./GoWithTheFlow"
import BackgroundImg from "../../resources/images/contact/contact-background.jpg"
import colors from "../../styles/colors"

const DarkBackground = styled.div`
    width: 100%;
    background: rgb(2,9,19);
    padding-left: 0;
    padding-right: 0;
`;

const ContactContainer = styled(Container)`
    padding: 0;
    z-index: 1;
`;

const StyledRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 33px;
    padding-right: 33px;    
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`;

const BoldText = styled.div`
    ${fonts.swissBlackExtended};
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.3px;
    color: ${colors.white};
    @media(min-width: 992px){
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.8px;
    }
`;

const GreyText = styled.div`
    ${fonts.gilroyRegular};
    color: ${colors.grey};
    font-size: 15px;
    line-height: 21px;
    padding-top: 40px;
    margin-bottom: 50px;
    @media(min-width: 992px){
        padding-top: 30px;
        max-width: 361px;
    }
`;

const ContactBlock = styled.div`
    margin-bottom: 42px;
    text-align: center;
    @media(min-width: 992px){
        text-align: unset;
        margin-left: 103px;
        padding-top: ${props => props.top ? "5px" : "0"};
        margin-bottom: ${props => props.top ? "45px" : "0"};
    }
`;

const ContactTitle = styled.div`
    ${fonts.gilroyBold};
    font-size: 12px;
    line-height: 14px;
    color: ${colors.white};
    letter-spacing: 2.8px;
    text-transform: uppercase;
    padding-bottom: 5px;
`;

const Email = styled.a`
    text-decoration: none;
    border: none;
    &:hover,
    &:active,
    &:focus,
    a:link, a:visited {
        -webkit-tap-highlight-color: transparent;
        background: transparent;
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
    } 
`;

const ContactText = styled.div`
    font-size: 15px;
    line-height: 21px;
    color: ${colors.mint};
    ${fonts.gilroyRegular};
    &:hover,
    &:active,
    &:focus {
        color: ${colors.navy};
    }
`;

const ContactBackground = styled.div`
    margin-top: -820px;
    z-index: 0;
    height: 859px;
    background: url(${BackgroundImg}) no-repeat 100% 100%;
    background-size: cover;
    width: 100vw;
    @media(min-width: 768px) {
        margin-top: -1100px;
        padding-bottom: 200px;
        height: 916px;
    }
`;

const Contact = (props) => {
  const {images} = props;
  return (
    <DarkBackground>
        <ContactContainer>
          <StyledRow>
            <ColStyled lg={6}>
              <Fade duration={500} delay={250}>
              <BoldText>
                Want to talk beer? Great. Us too.
              </BoldText>
              </Fade>
              <Fade duration={750} delay={500}>
                <GreyText>
                  There are a few different ways to get in touch with us, depending on what you’re after. Have a look at the
                  addresses below to make sure your enquiry docks in the right inbox.
                </GreyText>
              </Fade>
            </ColStyled>
            <ColStyled lg={3}>
              <ContactBlock top>
                <ContactTitle>Fancy a chat</ContactTitle>
                <Email href="mailto: ahoy@lostatsea.co.uk"><ContactText>ahoy@lostatsea.co.uk</ContactText></Email>
              </ContactBlock>
              <ContactBlock>
                <ContactTitle>Want to stock us</ContactTitle>
                <Email href="mailto: shipmates@lostatsea.co.uk"><ContactText>shipmates@lostatsea.co.uk</ContactText></Email>
              </ContactBlock>
            </ColStyled>
            <ColStyled lg={3}>
              <ContactBlock top>
                <ContactTitle>Want to work here</ContactTitle>
                <Email href="mailto: crew@lostatsea.co.uk"><ContactText>crew@lostatsea.co.uk</ContactText></Email>
              </ContactBlock>
              <ContactBlock>
                <ContactTitle>Want to support us</ContactTitle>
                <Email href="mailto: invest@lostatsea.co.uk"><ContactText>invest@lostatsea.co.uk</ContactText></Email>
              </ContactBlock>
            </ColStyled>
          </StyledRow>
        </ContactContainer>
        <JoinTheCrew {...images}/>
      <ContactBackground/>
    </DarkBackground>
  )
}


export default Contact;

