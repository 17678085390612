import React from 'react';
import { Fade } from 'react-reveal';
import fonts from '../../styles/fonts';
import styled from 'styled-components';
import SocialMediaLinks from './SocialLinks';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import Img from "gatsby-image"

const DesktopOnly = styled.div`
    display:block;
    @media(max-width: 1037px) {
        display:  none;
    }
`;

const MobileOnly = styled.div`
    display:block;
    @media(min-width: 1038px) {
        display:  none;
    }
`;

const JoinTheCrewContainer = styled(Container)`
    position: relative;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
    z-index: 1;
    @media(max-width: 768px) {
        padding: 25px 0 0 0;
    }
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
        @media(min-width: 768px) {
            display: ${props => props.mobileonly ? "none" : "block"}
        }
        @media(max-width: 768px) {
            display: ${props => props.desktoponly ? "none" : "block"}
        }
`;

const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    width: ${props => props.fullwidth ? "100%" : ""};
    height: ${props => props.fullwidth ? "100%" : ""};
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding-top: ${props => props.mobile ? "45px" : "120px"};
`;

const SecondRow = styled.div`
    padding-top: ${props => props.mobile ? "8px" : "20px"};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

const FirstRow1Styled = styled.div`
    height: 382px;
    padding-right: 20px;
    width: 281px;
`;

const FirstRow2Styled = styled.div`
    height: 407px;
    padding-right: 20px;
    width: 407px;
`;

const FirstRow3Styled = styled.div`
    height: 198px;
    width: 198px;
`;

const SecondRow1Styled = styled.div`
    height: 308px;
    position: relative;
    z-index: 10;
    margin-right: -41px;
    width: 237px;
`;

const SecondRow2Styled = styled.div`
    height: 407px;
    padding-left: 20px;
    width: 407px;
`;

const SecondRow3Styled = styled.div`
    height: 281px;
    padding-left: 20px;
    width: 381px;
`;

const SocialMediaContainer = styled.div`
    height: 198px;
    width: 360px;
    border-bottom: 5px solid #9DDCE2;
    position: absolute;
    z-index: 11;
    background: white;
    bottom: 315px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    justify-content: center;
`;

const SpacingDiv = styled.div`
    position: relative;
    width: 100%;
    bottom: -15px;
`;

const MediaTitle = styled.div`
    color: #9CA8B5;
    ${fonts.gilroyBold};
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    padding-top: ${props => props.mobile ? "60px" : "10px"};
    padding-bottom: ${props => props.mobile ? "38px" : "0px"};
`;

const FirstRow1StyledMobile = styled.div`
    height: 152px;
    margin-right: -19px;
    position: relative;
    z-index: 11;
    width: 141px;
    @media(max-width: 346px) {
        height: 142px;
    }
`;

const FirstRow2StyledMobile = styled.div`
    height: 171px;
    width: 204px;
`;

const SecondRow1StyledMobile = styled.div`
    height: 171px;
    padding-right: 8px;
    width: 171px;
`;

const SecondRow2StyledMobile = styled.div`
    height: 118px;
    width: 118px;
    @media(max-width: 346px) {
        height: 110px;
    }
`;

const JoinTheCrew = (props) => {
  const style = "dark";
  const jointhecrew = "true";
  return (
    <JoinTheCrewContainer>
      <RowStyled>
        <ColStyled>
          <DesktopOnly>
            <RowStyled>
              <ColStyled>
                <Fade duration={500}><FirstRow>
                  <FirstRow1Styled>
                    <Img fluid={props.JoinTheCrew1.childImageSharp.fluid} />
                  </FirstRow1Styled>
                  <FirstRow2Styled>
                    <Img fluid={props.JoinTheCrew2.childImageSharp.fluid} />
                  </FirstRow2Styled>
                  <FirstRow3Styled>
                    <Img fluid={props.JoinTheCrew3.childImageSharp.fluid} />
                  </FirstRow3Styled>
                </FirstRow></Fade>
              </ColStyled>
              <ColStyled>
                <Fade duration={500}> <SecondRow>
                  <SecondRow1Styled >
                    <Img fluid={props.JoinTheCrew4.childImageSharp.fluid} />
                  </SecondRow1Styled>
                  <SecondRow2Styled >
                    <Img fluid={props.JoinTheCrew5.childImageSharp.fluid} />
                  </SecondRow2Styled>
                  <SecondRow3Styled >
                    <Img fluid={props.JoinTheCrew6.childImageSharp.fluid} />
                  </SecondRow3Styled>
                </SecondRow></Fade>
              </ColStyled>
            </RowStyled>
            <Fade duration={750} >
              <SocialMediaContainer>
                <MediaTitle>
                  Go with the flow
                </MediaTitle>
                <SpacingDiv>
                  <SocialMediaLinks iconColor={style} paddingLeft={'40px'} />
                </SpacingDiv>
              </SocialMediaContainer>
            </Fade>
          </DesktopOnly>
          <MobileOnly>
            <RowStyled>
              <ColStyled>
                <Fade duration={500}> <FirstRow mobile>
                  <FirstRow1StyledMobile>
                    <Img fluid={props.MobileJoinTheCrew1.childImageSharp.fluid} />
                  </FirstRow1StyledMobile>
                  <FirstRow2StyledMobile>
                    <Img fluid={props.MobileJoinTheCrew2.childImageSharp.fluid} />
                  </FirstRow2StyledMobile>
                </FirstRow> </Fade>
                <Fade duration={500}><SecondRow mobile>
                  <SecondRow1StyledMobile >
                    <Img fluid={props.MobileJoinTheCrew3.childImageSharp.fluid} />
                  </SecondRow1StyledMobile>
                  <SecondRow2StyledMobile >
                    <Img fluid={props.MobileJoinTheCrew4.childImageSharp.fluid} />
                  </SecondRow2StyledMobile>
                </SecondRow></Fade>
              </ColStyled>
              <ColStyled><MediaTitle mobile>Go with the flow</MediaTitle></ColStyled>
              <ColStyled>
                <SocialMediaLinks style={{style}} jointhecrew={jointhecrew}/>
              </ColStyled>
            </RowStyled>
          </MobileOnly>
        </ColStyled>
      </RowStyled>
    </JoinTheCrewContainer>);
}

export default JoinTheCrew;
