import React from "react";
import { Zoom } from "react-reveal";
import styled from "styled-components";
import { Row } from "styled-bootstrap-grid";
import DecorationTitleImg1 from "../../resources/images/contact/decoration-title-1.svg";
import DecorationTitleImg2 from "../../resources/images/contact/decoration-title-2.svg";
import DecorationTitleImg3 from "../../resources/images/contact/decoration-title-3.svg";
import DecorationTitleImg4 from "../../resources/images/contact/decoration-title-4.svg";
import DecorationTitleImg5 from "../../resources/images/contact/decoration-title-5.svg";
import DecorationTitleImg6 from "../../resources/images/contact/decoration-title-6.svg";
import DecorationTitleImg7 from "../../resources/images/contact/decoration-title-7.svg";
import DecorationTitleImg8 from "../../resources/images/contact/decoration-title-8.svg";
import DecorationTitleImg9 from "../../resources/images/contact/decoration-title-9.svg";
import DecorationTitleMobileImg1 from "../../resources/images/contact/decoration-title-mobile-1.svg";
import DecorationTitleMobileImg2 from "../../resources/images/contact/decoration-title-mobile-2.svg";
import DecorationTitleMobileImg3 from "../../resources/images/contact/decoration-title-mobile-3.svg";
import DecorationTitleMobileImg4 from "../../resources/images/contact/decoration-title-mobile-4.svg";
import DecorationTitleMobileImg5 from "../../resources/images/contact/decoration-title-mobile-5.svg";
import DecorationTitleMobileImg6 from "../../resources/images/contact/decoration-title-mobile-6.svg";
import DecorationTitleMobileImg7 from "../../resources/images/contact/decoration-title-mobile-7.svg";
import DecorationTitleMobileImg8 from "../../resources/images/contact/decoration-title-mobile-8.svg";
import DecorationTitleMobileImg9 from "../../resources/images/contact/decoration-title-mobile-9.svg";

const TitleLetter = styled.img`
      height: 73px;
      @media(min-width: 1050px){
          height: 93px;
      }
`;

const TitleLetterMobile = styled.img`
      height: 52px;
`;

const DesktopOnly = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 283px;
    width: 100%;
    @media(max-width: 992px) {
        display:  none;
    }
`;

const MobileOnly = styled.div`
    display: block;
    padding-top: 200px;
    width: 100%;
    margin: auto;
    @media(min-width: 992px) {
        display:  none;
    }
`;

const FirstRow = styled(Row)`
    margin: auto auto 34px auto;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;

const SecondRow = styled(Row)`
    margin: auto;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

 const Break = styled.div`

`

const DecorationTitle = () => {
  return (
    <div>
      <DesktopOnly>
        <Zoom cascade duration={3000}>
          <TitleLetter src={DecorationTitleImg1}/>
          <TitleLetter src={DecorationTitleImg2}/>
          <TitleLetter src={DecorationTitleImg3}/>
          <TitleLetter src={DecorationTitleImg4}/>
          <Break/>
          <TitleLetter src={DecorationTitleImg5}/>
          <TitleLetter src={DecorationTitleImg6}/>
          <TitleLetter src={DecorationTitleImg7}/>
          <TitleLetter src={DecorationTitleImg8}/>
          <TitleLetter src={DecorationTitleImg9}/>
        </Zoom>
      </DesktopOnly>
      <MobileOnly>
          <FirstRow>
            <Zoom cascade duration={3000}>
              <TitleLetterMobile src={DecorationTitleMobileImg1}/>
              <TitleLetterMobile src={DecorationTitleMobileImg2}/>
              <TitleLetterMobile src={DecorationTitleMobileImg3}/>
              <TitleLetterMobile src={DecorationTitleMobileImg4}/>
            </Zoom>
          </FirstRow>
          <SecondRow>
            <Zoom cascade duration={3000}>
              <TitleLetterMobile src={DecorationTitleMobileImg5}/>
              <TitleLetterMobile src={DecorationTitleMobileImg6}/>
              <TitleLetterMobile src={DecorationTitleMobileImg7}/>
              <TitleLetterMobile src={DecorationTitleMobileImg8}/>
              <TitleLetterMobile src={DecorationTitleMobileImg9}/>
            </Zoom>
          </SecondRow>
      </MobileOnly>
    </div>
  )
}

export default DecorationTitle;
