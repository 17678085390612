import React from 'react';
import styled from 'styled-components';
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from 'gatsby';
import DecorationTitle from "./DecorationTitle"
import ShortOverlayBottom from "../core/ShortOverlayBottom"

const BackgroundStyled = styled.div`
    position: relative;
    height: 510px;
    margin-top: -138px;
    :after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 100px;
      background: linear-gradient(to bottom, rgba(0, 9, 20, 0), rgba(0, 9, 20, 1));
    }
    @media(min-width: 992px){
        height: 657px;
    }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100vw;
  min-height: 100%;
  background-size: cover;
`;

const Hero = () => (
  <StaticQuery query={graphql`
      query {
        background: file(relativePath: { eq: "contact/hero-background.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
               render={data => {
                 const imageData = data.background.childImageSharp.fluid;

                 return (
                   <BackgroundStyled>
                     <BackgroundImageStyled fluid={imageData} critical={true} durationFadeIn={100}>
                       <DecorationTitle/>
                       <ShortOverlayBottom/>
                     </BackgroundImageStyled>
                   </BackgroundStyled>
                 )
               }
               }
  />);

export default Hero;
