import React, { Component } from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import Hero from "../components/contact/Hero"
import Contact from "../components/contact/ContactBlock"
import get from "lodash/get"
import PrivateRoute from "../router/PrivateRoute"

class ContactPage extends Component {
  render() {
    const path = this.props.location.pathname
    const data = get(this, "props.data")
    const {
      page,
      backgroundImage,
      JoinTheCrew1,
      JoinTheCrew2,
      JoinTheCrew3,
      JoinTheCrew4,
      JoinTheCrew5,
      JoinTheCrew6,
      MobileJoinTheCrew1,
      MobileJoinTheCrew2,
      MobileJoinTheCrew3,
      MobileJoinTheCrew4,
    } = data;

    const imageProps = {
      JoinTheCrew1,
      JoinTheCrew2,
      JoinTheCrew3,
      JoinTheCrew4,
      JoinTheCrew5,
      JoinTheCrew6,
      MobileJoinTheCrew1,
      MobileJoinTheCrew2,
      MobileJoinTheCrew3,
      MobileJoinTheCrew4,
    }

    return (
      <Layout>
        <SEO title={page.metaTitle} description={page.metaDescription} fullTitle={false} path={path} />
        <Hero />
        <Contact backgroundImage={backgroundImage} images={imageProps} />
      </Layout>
    )
  }
}

function contact(props) {
  return <PrivateRoute component={ContactPage} {...props} />
}

export default contact

export const pageQuery = graphql`
  query ContactPageQuery {
    page: contentfulLandingPage(slug: { eq: "contact" }) {
      id
      metaTitle
      metaDescription
    }
    backgroundImage: file(
      relativePath: { eq: "contact/contact-background-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew1: file(relativePath: { eq: "home/join-01.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 562) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew2: file(relativePath: { eq: "home/join-02.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 814) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew3: file(relativePath: { eq: "home/join-03.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 396) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew4: file(relativePath: { eq: "home/join-04.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 474) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew5: file(relativePath: { eq: "home/join-05.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 814) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew6: file(relativePath: { eq: "home/join-06.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 762) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    MobileJoinTheCrew1: file(relativePath: { eq: "home/mobile-join-01.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 288) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    MobileJoinTheCrew2: file(relativePath: { eq: "home/mobile-join-02.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 408) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    MobileJoinTheCrew3: file(relativePath: { eq: "home/mobile-join-03.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    MobileJoinTheCrew4: file(relativePath: { eq: "home/mobile-join-04.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 236) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
