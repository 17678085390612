import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import InstagramIcon from '../../resources/images/icons/instagram.svg';
import FacebookIcon from '../../resources/images/icons/facebook.svg';
import TwitterIcon from '../../resources/images/icons/twitter.svg';
import VimeoIcon from '../../resources/images/icons/vimeo.svg';
import SpotifyIcon from '../../resources/images/icons/spotify.svg';

import InstagramIconDark from '../../resources/images/icons/instagram-dark.svg';
import FacebookIconDark from '../../resources/images/icons/facebook-dark.svg';
import TwitterIconDark from '../../resources/images/icons/twitter-dark.svg';
import VimeoIconDark from '../../resources/images/icons/vimeo-dark.svg';
import SpotifyIconDark from '../../resources/images/icons/spotify-dark.svg';
import ExternalLink from '../core/ExternalLink';

const Border = posed.div({
  hoverable: true,
  focusable: true,
  pressable: true,
  init: {
    height: "38.4px",
    width: "38.4px",
    rotate: 45,
    border: "1.5px solid rgba(157, 220, 226, 0)",
    scale: 0.83333333
  },
  hover: {
    height: "38.4px",
    width: "38.4px",
    rotate: 45,
    border: "1.5px solid rgba(157, 220, 226, 1)",
    scale: 1
  },
  press: {
    height: "38.4px",
    width: "38.4px",
    rotate: 45,
    border: "1.5px solid rgba(157, 220, 226, 1)",
    scale: 1
  },
  focus: {
    height: "38.4px",
    width: "38.4px",
    rotate: 45,
    border: "1.5px solid rgba(157, 220, 226, 1)",
    scale: 1
  }
});
const BorderStyled = styled(Border)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `;

const SocialLinksContainer = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: center;
        
    @media (min-width: 768px) {
        padding-bottom: 45px;
    
        &.dark {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0;
        }
        position: relative;
        bottom: 0;

    }  
`;

const IconStyled = styled.img`
    height: ${props => props.color === "dark" ? "21.6px" : "15.6px"};
    transform: rotate(-45deg);
`;

const StlyedLinks = styled(ExternalLink)`
    margin-right: ${props => props.color === "dark" ? (props.size === 'large' ? "25px": "15px" ): (props.size === 'large' ? "19px": "1px" )};
`;

const SocialLinks = (props) => {
  const color = props.iconColor;
  const paddingLeft = props.paddingLeft;
  const jointhecrew = props.jointhecrew;

  let links = [
    {
      href: 'https://www.instagram.com/lostatsea_co/',
      icon: InstagramIcon,
      darkIcon: InstagramIconDark,
      size: 'default'
    },
    {
      href: 'https://www.facebook.com/lostatseaco',
      icon: FacebookIcon,
      darkIcon: FacebookIconDark,
      size: 'default'
    },
    {
      href: 'https://twitter.com/lostatsea_co',
      icon: TwitterIcon,
      darkIcon: TwitterIconDark,
      size: 'default'
    },
    {
      href: 'https://vimeo.com/lostatseabrewing',
      icon: VimeoIcon,
      darkIcon: VimeoIconDark,
      size: 'default'
    },
    {
      href: 'https://open.spotify.com/playlist/3DbuRz2EwYM1znENa0cVIZ',
      icon: SpotifyIcon,
      darkIcon: SpotifyIconDark,
      size: 'large'
    }
  ];

  return (
    <SocialLinksContainer className={color === 'dark' || color === 'basic' ? 'dark' : ''} jointhecrew={jointhecrew} paddingLeft={paddingLeft}>
      {
        links.map((item) => {
          return (
            <StlyedLinks href={item.href} color={color} key={item.href} className={'styledLink'}>
              <BorderStyled>
                <IconStyled color={color} src={color === "dark" ? item.darkIcon: item.icon} size={item.size}/>
              </BorderStyled>
            </StlyedLinks>
          )
        })
      }
    </SocialLinksContainer>);
};

export default SocialLinks;
